import {action, observable} from 'mobx';
import {UnAuthorizedError} from '../services/advertising-api-service';
import {DealCampaign} from '../types';
import {RootStore} from './root-store';

export class DealsCampaignStore {

    @observable campaigns: DealCampaign[] = [];
    @observable apiServiceError: string | undefined;
    @observable userIsAuthorized: boolean = true;

    constructor(private rootStore: RootStore) {
        this.getCampaigns(true).then();
    }

    @action private readonly errorHandler = (error: Error) => {
        if (error instanceof UnAuthorizedError) {
            this.userIsAuthorized = false;
        } else {
            this.apiServiceError = error.message;
        }
        console.error('error encountered', error);
        return { error };
    }

    @action private setCampaigns = (campaigns: DealCampaign[]) => this.campaigns = campaigns;

    @action public publishCampaign = async (id: string, type: string) => {
        try {
            return await this.rootStore.dealsApiService
                .publishCampaign(id, type);
        } catch (error:any) {
            return this.errorHandler(error);
        }
    }

    @action public updateBudget = async (id: string, amountInCents: number) => {
        try {
            return await this.rootStore.dealsApiService
                .updateBudget(id, amountInCents);
        } catch (error: any) {
            return this.errorHandler(error);
        }
    }

    @action async saveCampaign(updatedCampaign: DealCampaign): Promise<DealCampaign> {
        return await this.rootStore.dealsApiService.postCampaign(updatedCampaign);
    }

    private getCampaigns = (isDraft: boolean) => this.rootStore.dealsApiService
        .getCampaigns(isDraft)
        .then(campaigns => {
            this.setCampaigns(campaigns);
        })
        .catch(this.errorHandler);

    async getCampaign(campaignId: string): Promise<DealCampaign | undefined> {
        const result = await this.rootStore
            .dealsApiService
            .getDraftCampaign(campaignId)
            .catch(this.errorHandler);
        return ((result as { error: Error }).error !== undefined)
            ? undefined
            : result as DealCampaign;
    }

    async getPublishedCampaign(campaignId: string): Promise<DealCampaign | undefined> {
        const result = await this.rootStore
            .dealsApiService
            .getPublishedCampaign(campaignId)
            .catch(this.errorHandler);
        return ((result as { error: Error }).error !== undefined)
            ? undefined
            : result as DealCampaign;
    }

    @action refreshCampaigns = async (isDraft: boolean) => await this.getCampaigns(isDraft);


    public readonly byId = (id?: string) => (campaign: DealCampaign) => campaign.id === id;

}
