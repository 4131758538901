import { BreadCrumbNavigation, Show } from "../components";
import {
  ClearTextButton, InputBoxBackgroundImage,
  NumberInputBox,
  PrimaryButton,
  Row,
  WarningButton
} from "../components/global/global-components";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { DealCampaign } from "../types";
import { useStore } from "../stores/store-context";
// @ts-ignore
import { Spinner } from "react-bootstrap";
import { FullScreenModal } from "../components/global/modal";
import { ModalCampaignTitle, ModalControls, ModalText } from "../components/modal/modal";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { receiptSchema, receiptUiSchema, tomraSchema, tomraUiSchema } from "../schema/rjsf-schema";
import { widgets } from "../components/rjsf/widgets";

export const ViewPublishedPage = observer(() => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { dealsCampaignStore } = useStore();
  const [campaign, setCampaign] = useState({} as DealCampaign);
  const [isLoading, setIsLoading] = useState(true);
  const [showUpdateBudgetConfirmation, setShowUpdateBudgetModal] = useState(false);
  const [isUpdatingBudget, setIsUpdatingBudget] = useState(false);
  const [amountInCents, setAmountInCents] = useState(0);
  const [schema, setSchema] = useState(tomraSchema);
  const [uiSchema, setUiSchema] = useState(tomraUiSchema);

  useEffect(() => {
    dealsCampaignStore.getPublishedCampaign(campaignId)
      .then((campaign) => {
        if (campaign === undefined) {
          throw new Error('something errored');
        }
        if (campaign.type === 'receipt') {
          setSchema(receiptSchema);
          setUiSchema(receiptUiSchema)
        }

        if (campaign.type === 'tomra') {
          setSchema(tomraSchema);
          setUiSchema(tomraUiSchema)
        }

        setCampaign(campaign);
      })
      .catch((e) => {
        console.error('Failed to fetch campaign: ', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [])

  const renderUpdateBudgetModal = () => {
    if (showUpdateBudgetConfirmation) {
      return (
        <FullScreenModal>
          <ModalText><ModalCampaignTitle>{campaign.campaignData.campaignName}</ModalCampaignTitle> Budget update</ModalText>

          <Show when={!isUpdatingBudget}>

            Amount in cents :

            <NumberInputBox customStyle={{ backgroundPosition: "center left 7px", paddingLeft: "55px", flexGrow: 1 }}
              backgroundImage={InputBoxBackgroundImage.euroBlueSquareBackgroundImage}
              value={amountInCents}
              onChange={newAmount => {
                const amountToBeUpdated = newAmount === undefined ? 0 : newAmount
                setAmountInCents(amountToBeUpdated);
              }}
            />
            <ModalControls>
              <WarningButton onClick={() => updateBudget()}><span style={{ paddingLeft: '12px' }}>Update</span></WarningButton>
              <ClearTextButton onClick={() => { setShowUpdateBudgetModal(false) }}>Cancel</ClearTextButton>
            </ModalControls>
          </Show>
          <Show when={isUpdatingBudget}>
            <div>updating....</div>
          </Show>
        </FullScreenModal>
      )
    }
  }

  const showUpdateBudgetModal = () => {
    setShowUpdateBudgetModal(true);
  }

  const returnBudgetInEuro =  (amount:number | undefined ): string => {
      return((amount ?? 0) / 100).toFixed(2);
  }

  const updateBudget = () => {
    setIsUpdatingBudget(true);
    dealsCampaignStore.updateBudget(campaign.id, amountInCents)
      .then((response) => {
      })
      .catch((error) => console.error(`error`, error)
      )
      .finally(() => {
        setShowUpdateBudgetModal(false);
        setIsUpdatingBudget(false)
      });
  }

  return (
    <div className={"page"} style={{ position: 'relative' }}>
      <Row>
        <BreadCrumbNavigation crumbs={[{ label: 'Deals' }, { link: '/deals-campaign-support', label: 'Deals' }, { label: 'New' }]} />
      </Row>

      {isLoading ? (<div><Spinner animation={"border"} size={"sm"} /> Loading...</div>) : (
          <>
              <div style={{padding: '20px', color: '#dc3545'}}>Totaal budget:
                  €{returnBudgetInEuro(campaign.budget?.totalFundedInCents)}</div>
              <div style={{padding: '20px', color: '#dc3545'}}>Gereserveerd budget:
                  €{returnBudgetInEuro(campaign.budget?.reservedInCents)}</div>
              <div style={{padding: '20px', color: '#dc3545'}}>Uitbetaald: €{returnBudgetInEuro(campaign.budget?.totalPaidOutInCents)}</div>
              <div style={{padding: '20px', color: '#dc3545'}}>Beschikbaar budget:
                  €{returnBudgetInEuro(campaign.budget?.availableInCents)}</div>


              <PrimaryButton onClick={showUpdateBudgetModal}> Add budget</PrimaryButton>
              <Form
                  formData={campaign}
                  readonly
                  widgets={widgets}
                  uiSchema={uiSchema}
                  schema={schema}
                  validator={validator}
              />
              <div className={'float-right'}>
                  {renderUpdateBudgetModal()}
              </div>
          </>
      )}
    </div>
  )
});
