import {Configuration, DealCampaign} from '../types'
import { CashbackCampaign, DraftCashbackCampaign } from "../types/cashback-campaign";
import fetch, { Response } from "node-fetch";
import { UnAuthorizedError } from "./advertising-api-service";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createDealsApiService = (configuration: Configuration) => {
    const { bearerTokenKey, baseUrlDealsService } = configuration

    const headers =    {
        'Authorization': `${localStorage.getItem(bearerTokenKey)}`,
    };

    return {
        getCampaigns: async (isDraft :boolean): Promise<DealCampaign[]> => {
            const type = isDraft ? 'com.support.get.draft.campaigns' : 'com.support.get.published.campaigns';
            const result = await fetch(`${baseUrlDealsService}/events`, {
                headers: headers,
                method: 'POST',
                body: JSON.stringify({
                    "version": "1.0",
                    "type": type,
                    "data": {}
                })
            });
            const json = await result.json();
            return json as unknown as DealCampaign[];

        },

        publishCampaign: async (id: string, type:string): Promise<CashbackCampaign> => {
            const result = await fetch(`${baseUrlDealsService}/events`, {
                headers: headers,
                method: 'POST',
                body: JSON.stringify({
                    "version": "1.0",
                    "type": "com.support.publish.campaign",
                    "data": {
                        id:id,
                        type:type
                    }
                })
            });
            return result.json();
        },
        updateBudget: async (id: string, amountInCents:number): Promise<CashbackCampaign> => {
            const result = await fetch(`${baseUrlDealsService}/events`, {
                headers: headers,
                method: 'POST',
                body: JSON.stringify({
                    "version": "1.0",
                    "type": "com.support.update.budget",
                    "data": {
                        id:id,
                        amountInCents:amountInCents
                    }
                })
            });
            return result.json();
        },
        getDraftCampaign: async (campaignId: string): Promise<DealCampaign> => {
            const result = await fetch(`${baseUrlDealsService}/events`, {
                headers: headers,
                method: 'POST',
                body: JSON.stringify({
                    "version": "1.0",
                    "type": "com.support.get.draft.campaign",
                    "data": {
                        id:campaignId
                    }
                })
            });
            const json = await result.json();
            return json as unknown as DealCampaign;
        },

        getPublishedCampaign: async (campaignId: string): Promise<DealCampaign> => {
            const result = await fetch(`${baseUrlDealsService}/events`, {
                headers: headers,
                method: 'POST',
                body: JSON.stringify({
                    "version": "1.0",
                    "type": "com.support.get.published.campaign",
                    "data": {
                        id:campaignId
                    }
                })
            });
            const json = await result.json();
            return json as unknown as DealCampaign;
        },

        postCampaign: async (campaign: DealCampaign): Promise<DealCampaign> => {
            const result = await fetch(`${baseUrlDealsService}/events`, {
                headers: headers,
                method: 'POST',
                body: JSON.stringify({
                    "version": "1.0",
                    "type": "com.support.add.draft.campaign",
                    "data": campaign
                })
            });
            // TODO:rethink how to handle this in a better way for example returning the new campaign from BE
            if (result.status >= 200 && result.status < 300) {
                return campaign;
            } else {
                const response = await result.json();
                throw new Error(response.message);
            }
        },

    };
}
